import React from 'react';
import Table from './components/Table';
import './App.css';

function App() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Projexions Sports Betting Analytics Dashboard</h1>
      {/*<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
        <img src="projexions_horizontal_logo.jpg" alt="Projexions" style={{ maxWidth: '100%', height: '80px' }} />
      </div>*/}
      <Table />
    </div>
  );
}

export default App;
