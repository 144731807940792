import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/Table.module.css';

interface Item {
    league: string;
    name: string;
    propType: string;
    prizepicksLine: number;
    fanduelLine: number;
    odds: number;
    lineDiscrepancy: number;
    boardTime: string;
    lastUpdated: string;
    overUnder: string;
}

const Table: React.FC = () => {
    const [items, setItems] = useState<Item[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [sortKey, setSortKey] = useState<keyof Item | null>(null); // Added sortKey state

    useEffect(() => {
        axios.get<Item[]>('https://ody5ckdoghxm54chaiiygsal5e0baqff.lambda-url.us-east-1.on.aws/')
            .then((response) => setItems(response.data))
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const sortItems = (key: keyof Item) => {
        const sortedItems = [...items].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[key] < b[key] ? -1 : 1;
            } else {
                return a[key] > b[key] ? -1 : 1;
            }
        });
        setItems(sortedItems);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setSortKey(key); // Set the current sort key
    };

    // Function to convert odds to percentage
    const convertOddsToPercentage = (odds: number): string => {
        let percentage: number;
        if (odds > 0) {
            percentage = 100 / (odds + 100) * 100;
        } else {
            percentage = Math.abs(odds) / (Math.abs(odds) + 100) * 100;
        }
        return percentage.toFixed(2) + '%';
    };

    const getRowClassName = (odds: number) => {
        if (odds <= -140) {
            return styles.veryHighOdds;
        } else if (odds <= -130) {
            return styles.highOdds;
        } else if (odds <= -120) {
            return styles.mediumHighOdds;
        } else if (odds <= -110) {
            return styles.mediumOdds;
        } else if (odds <= -100) {
            return styles.mediumLowOdds;
        } else if (odds > 0) {
            return styles.lowOdds;
        } else {
            return styles.veryLowOdds;
        }
    };

    const renderSortArrow = (key: keyof Item) => {
        if (sortKey !== key) return null; // Compare sortKey with key
        return sortOrder === 'asc' ? '▲' : '▼';
    };

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th onClick={() => sortItems('league')} style={{ cursor: 'pointer' }}>
                        League {renderSortArrow('league')}
                    </th>
                    <th onClick={() => sortItems('name')} style={{ cursor: 'pointer' }}>
                        Player Name {renderSortArrow('name')}
                    </th>
                    <th onClick={() => sortItems('propType')} style={{ cursor: 'pointer' }}>
                        Projection Type {renderSortArrow('propType')}
                    </th>
                    <th onClick={() => sortItems('overUnder')} style={{ cursor: 'pointer' }}>
                        Over/Under {renderSortArrow('overUnder')}
                    </th>
                    <th onClick={() => sortItems('prizepicksLine')} style={{ cursor: 'pointer' }}>
                        PrizePicks Line {renderSortArrow('prizepicksLine')}
                    </th>
                    <th onClick={() => sortItems('fanduelLine')} style={{ cursor: 'pointer' }}>
                        FanDuel Line {renderSortArrow('fanduelLine')}
                    </th>
                    <th onClick={() => sortItems('lineDiscrepancy')} style={{ cursor: 'pointer' }}>
                        Line Discrepancy {renderSortArrow('lineDiscrepancy')}
                    </th>
                    <th onClick={() => sortItems('odds')} style={{ cursor: 'pointer' }}>
                        % Chance to Hit {renderSortArrow('odds')}
                    </th>
                    {/*
                    <th onClick={() => sortItems('boardTime')} style={{ cursor: 'pointer' }}>
                        Original Board Time {renderSortArrow('boardTime')}
                    </th>
                    <th onClick={() => sortItems('lastUpdated')} style={{ cursor: 'pointer' }}>
                        Last Update {renderSortArrow('lastUpdated')}
                    </th>
                    */}
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <tr key={index} className={getRowClassName(item.odds)}>
                        <td>{item.league}</td>
                        <td>{item.name}</td>
                        <td>{item.propType}</td>
                        <td>{item.overUnder}</td>
                        <td>{item.prizepicksLine}</td>
                        <td>{item.fanduelLine}</td>
                        <td>{item.lineDiscrepancy}</td>
                        {/* Changed odds display to percentage */}
                        <td>{convertOddsToPercentage(item.odds)}</td>
                        {/*<td>{item.boardTime}</td>*/}
                        {/*<td>{item.lastUpdated}</td>*/}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
